
import type { PropType } from "vue";
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { SortType } from "@/types/main";
import AppInput from "@/components/app/AppInput.vue";
import AppButton from "@/components/app/AppButton.vue";

export interface TableField {
  key: string;
  label: string;
  type?: string;
  sortable?: boolean;
  width?: number;
}
export interface TableComponentValue {
  tag: string;
  props?: { [key: string]: any };
  value?: any;
  events?: { [key: string]: any };
}

export default Vue.extend({
  name: "AppTable",
  components: { SvgIcon, AppInput, AppButton },
  props: {
    defaultSortedBy: { type: String, default: "" },
    fields: {
      type: Array as PropType<Array<TableField>>,
      default: () => [],
    },
    items: {
      type: Array as PropType<Array<string | number | TableComponentValue>>,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data: (): {
    page: number;
    sortBy: string;
    sortType: SortType;
    SortType: typeof SortType;
    search: string;
  } => ({
    page: 1,
    sortBy: "",
    sortType: SortType.ASC,
    SortType: SortType,
    search: "",
  }),
  computed: {
    sumWidths(): number {
      return this.fields.reduce(function (a, b) {
        return a + (b.width || 1);
      }, 0);
    },
    sortByField(): TableField | undefined {
      return this.fields.find((field) => field.key === this.sortBy);
    },
    filteredItems(): Array<any> {
      if (!this.search?.length) return this.items;
      return this.items?.filter((item) =>
        Object.values(item).find(
          (i) =>
            i &&
            i
              .toString()
              ?.toLowerCase()
              ?.includes((this.search || "").toLowerCase())
        )
      );
    },
    sortedItems(): Array<any> {
      const field = this.sortByField;
      let items = [...this.filteredItems];
      if (field)
        items = items.sort(function (a, b) {
          if (field?.type === "string") {
            return (a[field.key] || "")
              ?.toLowerCase()
              ?.trim()
              .localeCompare((b[field.key] || "")?.toLowerCase()?.trim());
          } else if (field?.type === "number") {
            return a[field.key] - b[field.key];
          }
          return a[field.key] < b[field.key] ? -1 : 0;
        });
      if (this.sortType === SortType.DESC) return items.reverse();
      return items;
    },
    itemsSlicedPerPage(): Array<any> {
      if (!this.itemsPerPage) return this.sortedItems;
      return [...this.sortedItems].slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },

    totalPages(): number {
      if (!this.itemsPerPage) {
        return this.sortedItems.length;
      }
      return Math.ceil(this.sortedItems.length / this.itemsPerPage);
    },
  },
  watch: {
    fields: {
      immediate: true,
      handler: function (val: Array<TableField>): void {
        if (this.defaultSortedBy?.length) {
          this.sortBy = this.defaultSortedBy;
        } else {
          const field = val.find((f) => f.sortable && f.type === "string");
          if (field) {
            this.sortBy = field.key;
          }
        }
      },
    },
  },
  methods: {
    changeSort(field: TableField): void {
      if (field.sortable) {
        if (field.key === this.sortBy) {
          this.sortType =
            this.sortType === SortType.ASC ? SortType.DESC : SortType.ASC;
        } else {
          this.sortBy = field.key;
          this.sortType = SortType.ASC;
        }
      }
    },
  },
});
