
import { mapActions, mapGetters } from "vuex";
import UserForm from "@/components/UserForm.vue";
import { User, UserRole } from "@/types/user";
import Vue from "vue";
import { ModuleNames } from "@/store";

export default Vue.extend({
  name: "AccountSettingsView",
  components: { UserForm },
  // middleware: ["is-authenticated"],
  data: () => ({ UserRole }),
  computed: {
    ...mapGetters(ModuleNames.auth, ["isApproved", "authUser"]),
  },
  methods: {
    // ...mapActions("user", ["updateUserProfile"]),

    async updateUser(user: User): Promise<void> {
      // this.$nuxt.$loading.start();
      // try {
      //   // await this.updateUserProfile(user);
      //   // this.showSuccessToast(this.$t("dataUpdated") as string);
      // } catch (e: any) {
      //   // this.showErrorToast(
      //   //   (this.$t("returnedError") as string) +
      //   //     (e.response.data.message
      //   //       ? ((",<br>" + this.$t("details")) as string) +
      //   //         ": " +
      //   //         e.response.data.message
      //   //       : "")
      //   // );
      // } finally {
      //   // this.$nuxt.$loading.finish();
      // }
    },
  },
});
