
import { mapGetters } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";

export default Vue.extend({
  name: "AccountView",
  // middleware: ["is-authenticated"],
  computed: {
    ...mapGetters(ModuleNames.auth, ["isApproved"]),
  },
});
