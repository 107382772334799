
import { mapActions, mapGetters } from "vuex";
import AppTable, {
  TableComponentValue,
  TableField,
} from "@/components/app/table/AppTable.vue";
import { User, UserRole } from "@/types/user";
import AppTableSkeleton from "@/components/app/table/AppTableSkeleton.vue";
import AppModal from "@/components/app/modal/AppModal.vue";
import Vue from "vue";
import { ModuleNames } from "@/store";

export default Vue.extend({
  name: "UsersView",
  components: { AppModal, AppTableSkeleton, AppTable },
  data: function () {
    return {
      approvingUsers: [] as Array<string>,
      disabledUsersRow: [] as Array<string>,
      loading: false,
      deleteUserModal: {
        userId: "",
        visible: false,
        title: this.$t("areYouSure") as string,
        description: this.$t("users.deleteUser") as string,
        confirmButtonText: this.$t("users.yesApprove") as string,
      },

      confirmUserModal: {
        userId: "",
        visible: false,
        title: this.$t("areYouSure") as string,
        description: this.$t("users.approveUser") as string,
        confirmButtonText: this.$t("users.yesApprove") as string,
      },
    };
  },

  computed: {
    ...mapGetters(ModuleNames.auth, ["authUser"]),
    ...mapGetters(ModuleNames.user, ["users"]),

    userTableFields(): Array<TableField> {
      return [
        { key: "avatar", label: "", type: "image" },
        { key: "name", label: this.$t("users.name") as string, width: 3 },
        { key: "roles", label: this.$t("users.role") as string, width: 2 },
        {
          key: "createdAt",
          label: this.$t("users.createdAt") as string,
          type: "date",
        },
        {
          key: "approved",
          label: this.$t("users.approved") as string,
          type: "component",
          width: 2,
        },
        {
          key: "email",
          label: this.$t("users.email") as string,
          type: "email",
          width: 2,
        },
        {
          key: "phone",
          label: this.$t("users.phone") as string,
          type: "phone",
          width: 2,
        },
        {
          key: "actions",
          label: "",
          type: "component",
        },
      ];
    },

    usersTableItems(): Array<any> {
      if (!this.users) return [];
      return [...this.users]
        .map((user) => {
          const disabled = Boolean(
            user.id && this.disabledUsersRow.includes(user.id)
          );
          return {
            disabled,
            avatar: user.avatar,
            name: user.displayName,
            roles: user.roles?.join(", "),
            createdAt: user.createdAt
              ? new Date(user.createdAt).toLocaleString()
              : "",
            approved: this.getApprovedCellContent(user, disabled),
            email: user.email,
            phone: user.phone,
            actions: this.getActionsCellContent(user, disabled),
          };
        })
        .sort((a, b) => (a.name < b.name ? -1 : 0));
    },
  },
  async mounted(): Promise<void> {
    if (!this.users.length) {
      try {
        this.loading = true;
        await this.fetchUsersList();
      } catch (e: any) {
        // this.showErrorToast(
        //   (this.$t("returnedError") as string) +
        //     (e.response.data.message
        //       ? ((",<br>" + this.$t("details")) as string) +
        //         ": " +
        //         e.response.data.message
        //       : "")
        // );
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions(ModuleNames.user, [
      "fetchUsersList",
      "confirmUser",
      "deleteUser",
    ]),

    getApprovedCellContent(user: User, disabled: boolean): TableComponentValue {
      if (user.approved && user.approvedAt)
        return {
          tag: `p`,
          props: {
            class: "truncate block",
          },
          value:
            this.$t("Yes") + ", " + new Date(user.approvedAt).toLocaleString(),
        };

      if (user.approved)
        return {
          tag: `p`,
          value: this.$t("Yes"),
        };

      if (this.authUser.roles?.includes(UserRole.MODERATOR)) {
        const isLoading = user.id && this.approvingUsers.includes(user.id);

        return {
          tag: `AppButton`,
          props: {
            backgroundColor: "indigo",
            disabled: disabled,
            loading: isLoading,
            text: this.$t("Confirm"),
            class: "w-28",
            size: "sm",
          },
          events: {
            click: {
              event: "confirmUserAccount",
              value: user.id,
            },
          },
        };
      }
      return {
        tag: `p`,
        value: this.$t("No"),
      };
    },
    getActionsCellContent(
      user: User,
      disabled: boolean
    ): TableComponentValue | string {
      if (
        this.authUser.roles?.includes(UserRole.ADMIN) &&
        this.authUser.id !== user.id
      )
        return {
          tag: `SvgIcon`,
          props: {
            icon: "trash",
            disabled: disabled,
            class: `svg-icon--tiny text-red-600 ${
              !disabled ? "hover:text-red-700" : ""
            } cursor-pointer`,
          },
          events: {
            click: {
              event: "deleteUserAccount",
              value: user.id,
            },
          },
        };

      return "";
    },

    emittedFromTable({ event, value }: { event: string; value: any }): void {
      if (event === "confirmUserAccount") {
        this.confirmUserModal.visible = true;
        this.confirmUserModal.userId = value as string;
      }
      if (event === "deleteUserAccount") {
        this.deleteUserModal.visible = true;
        this.deleteUserModal.userId = value as string;
      }
    },

    async confirmUserAccount(): Promise<void> {
      const userId = this.confirmUserModal.userId;
      try {
        this.disabledUsersRow.push(userId);
        this.confirmUserModal.userId = "";
        this.confirmUserModal.visible = false;
        await this.confirmUser(userId);
        // this.showSuccessToast(this.$t("users.userHasBeenApproved") as string);
      } catch (e: any) {
        // this.showErrorToast(
        //   (this.$t("returnedError") as string) +
        //     (e.response.data.message
        //       ? ((",<br>" + this.$t("details")) as string) +
        //         ": " +
        //         e.response.data.message
        //       : "")
        // );
      } finally {
        this.disabledUsersRow = this.disabledUsersRow.filter(
          (item) => item !== userId
        );
      }
    },

    async deleteUserAccount(): Promise<void> {
      const userId = this.deleteUserModal.userId;
      try {
        this.disabledUsersRow.push(userId);
        this.deleteUserModal.userId = "";
        this.deleteUserModal.visible = false;
        await this.deleteUser(userId);
        // this.showSuccessToast(this.$t("users.userHasBeenDeleted") as string);
      } catch (e: any) {
        // this.showErrorToast(
        //   (this.$t("returnedError") as string) +
        //     (e.response.data.message
        //       ? ((",<br>" + this.$t("details")) as string) +
        //         ": " +
        //         e.response.data.message
        //       : "")
        // );
      } finally {
        this.disabledUsersRow = this.disabledUsersRow.filter(
          (item) => item !== userId
        );
      }
    },
  },
});
