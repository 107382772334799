import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import store, { initAuth, ModuleNames } from "@/store";
import { TokenService } from "@/services/token.service";
import AccountView from "@/views/account/AccountView.vue";
import AccountSettingsView from "@/views/account/AccountSettingsView.vue";
import RegisterView from "@/views/RegisterView.vue";
import UsersView from "@/views/users/UsersView.vue";
import { UserRole } from "@/types/user";
import GamesView from "@/views/games/GamesView.vue";
import GameView from "@/views/games/GameView.vue";
import ContestView from "@/views/ContestView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      isGuest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      isGuest: true,
    },
  },
  {
    path: "/account/settings",
    name: "account-settings",
    component: AccountSettingsView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      isAuthenticated: true,
      isModerator: true,
    },
  },
  {
    path: "/games",
    name: "games",
    component: GamesView,
  },
  {
    path: "/games/:game_uuid",
    name: "game",
    component: GameView,
  },
  {
    path: "/games/:game_uuid/:contest_uuid",
    name: "contest",
    component: ContestView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setIsLoading", true);
  const isAuthenticated = store.getters[`${ModuleNames.auth}/isAuthenticated`];
  const roles = store.getters[`${ModuleNames.auth}/authUser`]?.roles || [];
  const accessToken = TokenService.getToken();
  if (!isAuthenticated && accessToken) await initAuth();
  if (to.meta?.isGuest && isAuthenticated) {
    next({ name: "account" });
  }
  if (to.meta?.isAuthenticated && !isAuthenticated) {
    next({ name: "login" });
  }
  if (to.meta?.isModerator && !roles?.includes(UserRole.MODERATOR)) {
    next({ name: "login" });
  }
  next();

  setTimeout(() => {
    store.commit("setIsLoading", false);
  }, 300);
});

export default router;
