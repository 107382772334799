
import type { PropType } from "vue";
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default Vue.extend({
  name: "AppListItem",
  components: { SvgIcon },
  props: {
    item: {
      type: Object as PropType<{
        logo?: string;
        id: string;
        title: string;
        description?: string;
        details?: string;
      }>,
      // default: () => {},
    },
  },
  computed: {
    logo(): string {
      if (this.item?.logo) {
        return this.item.logo;
      }
      if (this.item)
        return `https://avatars.dicebear.com/api/initials/${(
          this.item?.title + this.item?.id
        )
          .trim()
          .replace(/ /g, "-")}.svg`;
      return "";
    },
  },
});
