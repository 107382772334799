import { TokenService } from "@/services/token.service";
import axios from "axios";
import store, { ModuleNames } from "@/store";
import { AuthActions } from "@/store/auth";

export default function authHeader() {
  const accessToken = TokenService.getToken();

  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  headers: authHeader(),
});
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(`${ModuleNames.auth}/${AuthActions.LOGOUT}`, null, {
        root: true,
      });
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export { axiosInstance };
