import { User } from "@/types/user";
import { axiosInstance } from "@/helpers/auth";

const UserService = {
  registerNewUser: async function (payload: {
    email: string;
    password: string;
    displayName: string;
    confirmPassword: string;
  }): Promise<{ accessToken: string; user: User }> {
    return await axiosInstance({
      method: "post",
      url: `/api/auth/register`,
      data: payload,
    });
  },
  getUsersList: async function (): Promise<{ users: Array<User> }> {
    return await axiosInstance({
      method: "get",
      url: `/api/users`,
    });
  },
  confirmUser: async function (
    userId: string
  ): Promise<{ users: Array<User> }> {
    return await axiosInstance({
      method: "put",
      url: `/api/users/${userId}/confirm`,
    });
  },
  deleteUser: async function (userId: string): Promise<{ users: Array<User> }> {
    return await axiosInstance({
      method: "delete",
      url: `/api/users/${userId}`,
    });
  },
};
export default UserService;
