
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import AppButton from "@/components/app/AppButton.vue";

export default Vue.extend({
  name: "AppModal",
  components: { AppButton, SvgIcon },
  props: {
    visible: { type: Boolean, default: false },
    hideConfirmButton: { type: Boolean, default: false },
    hideCancelButton: { type: Boolean, default: false },
    description: { type: String, default: "" },
    title: { type: String, default: "" },
    icon: { type: String, default: "" },
    iconColor: { type: String, default: "" },
    confirmButtonText: { type: String, default: "OK" },
    loading: { type: Boolean, default: false },
  },
  data: function () {
    return {
      cancelText: this.$t("cancel") as string,
    };
  },
  computed: {},
  methods: {
    close(): void {
      if (this.visible) this.$emit("close");
    },
  },
});
