export enum UserRole {
  ADMIN = "admin",
  MODERATOR = "moderator",
  USER = "user",
}

export interface User {
  _id?: string;
  id?: string; //todo: choose one id
  displayName: string;
  email: string;
  phone?: string;
  avatar?: string;
  roles?: Array<UserRole>;
  approved: boolean;
  createdAt?: string;
  approvedAt?: string;
}
