import type { Commit, Dispatch } from "vuex";
import { User } from "@/types/user";
import UserService from "@/services/user.service";

export enum UserActions {
  RESET_PASSWORD = "resetPassword",
  UPDATE_USER_PROFILE = "updateUserProfile",
  REGISTER_NEW_USER = "registerNewUser",
  FETCH_USERS_LIST = "fetchUsersList",
  CONFIRM_USER = "confirmUser",
  DELETE_USER = "deleteUser",
}

export enum UserMutations {
  SET_USERS_LIST = "setUsersList",
}
export interface UserState {
  users: Array<User>;
}
export const state = (): UserState => ({
  users: [],
});

export const getters = {
  users: (state: UserState) => state.users,
};

export const actions = {
  async [UserActions.REGISTER_NEW_USER](
    // eslint-disable-next-line no-empty-pattern
    {},
    user: {
      email: string;
      password: string;
      displayName: string;
      confirmPassword: string;
    }
  ) {
    await UserService.registerNewUser(user);
  },
  async [UserActions.FETCH_USERS_LIST]({ commit }: { commit: Commit }) {
    const response = await UserService.getUsersList();
    commit(UserMutations.SET_USERS_LIST, response.users);
  },
  async [UserActions.CONFIRM_USER](
    { dispatch }: { dispatch: Dispatch },
    userId: string
  ) {
    await UserService.confirmUser(userId);
    await dispatch(UserActions.FETCH_USERS_LIST);
  },
  async [UserActions.DELETE_USER](
    { dispatch }: { dispatch: Dispatch },
    userId: string
  ) {
    await UserService.deleteUser(userId);
    await dispatch(UserActions.FETCH_USERS_LIST);
  },
};

export const mutations = {
  [UserMutations.SET_USERS_LIST](state: UserState, payload: Array<User>) {
    state.users = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
