import Vue from "vue";
import Vuex, { Commit } from "vuex";
import auth, { AuthActions, AuthMutations } from "@/store/auth";
import user from "@/store/user";
import game from "@/store/game";
import { i18n } from "@/main";
import { Route } from "vue-router/types/router";
import { Breadcrumb } from "@/types/main";
export enum ModuleNames {
  auth = "auth",
  user = "user",
  game = "game",
}
Vue.use(Vuex);
export const RootMutations = {
  SET_IS_LOADING: "setIsLoading",
  SET_BREADCRUMBS: "setBreadcrumbs",
};
export const RootActions = {
  UPDATE_BREADCRUMBS: "updateBreadcrumbs",
  CORRECT_BREADCRUMBS_LABEL: "correctBreadcrumbsLabel",
};
export interface RootState {
  isLoading: boolean;
  breadcrumbs: Array<Breadcrumb>;
}
export const state = (): RootState => ({
  isLoading: false,
  breadcrumbs: [],
});
export const getters = {
  isLoading: (state: RootState) => state.isLoading,
  breadcrumbs: (state: RootState) => state.breadcrumbs,
};
const store = new Vuex.Store({
  state,
  getters,
  mutations: {
    [RootMutations.SET_IS_LOADING](state, payload) {
      state.isLoading = payload;
    },
    [RootMutations.SET_BREADCRUMBS](state, payload) {
      state.breadcrumbs = payload;
    },
  },
  actions: {
    [RootActions.CORRECT_BREADCRUMBS_LABEL](
      { commit }: { commit: Commit },
      breadcrumbs: Array<{ link: string; label: string }>
    ) {
      commit(RootMutations.SET_BREADCRUMBS, breadcrumbs);
    },
    [RootActions.UPDATE_BREADCRUMBS](
      { commit }: { commit: Commit },
      route: Route
    ) {
      const breadcrumbs: Array<Breadcrumb> = [];
      const path = route.path;
      if (path !== "/" && path !== "") {
        const routes = path.replace("/", "").split("/");

        if (routes.length >= 1) {
          let link = "";
          routes.forEach((item: string) => {
            link = link + "/" + item;
            breadcrumbs.push({
              label: i18n.t("navbar." + item) as string,
              link: link,
            });
          });
        }
      }
      commit(RootMutations.SET_BREADCRUMBS, breadcrumbs);
    },
  },
  modules: {
    auth,
    user,
    game,
  },
});
export async function initAuth() {
  return store.dispatch(`${ModuleNames.auth}/${AuthActions.AUTH}`);
}
export default store;
