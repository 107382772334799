var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto"},[_c('div',{staticClass:"inline-block min-w-full shadow rounded-lg overflow-hidden"},[_c('table',{staticClass:"min-w-full leading-normal"},[(_vm.columns)?_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column,class:[
              `px-3
              py-3
              bg-white
              border-b border-gray-200
              text-gray-800 text-left text-sm
              uppercase
              font-normal`,
              { 'w-12': _vm.circleColumns.includes(column) },
            ],attrs:{"scope":"col"}},[(!_vm.circleColumns.includes(column))?_c('div',{staticClass:"h-4 bg-gray-200 rounded animate-pulse"}):_vm._e()])}),0)]):_vm._e(),_c('tbody',_vm._l((5),function(row){return _c('tr',{key:'item_' + row},_vm._l((_vm.columns),function(column){return _c('td',{key:'item_' + row + '_' + column,staticClass:"px-3 py-3 border-b border-gray-200 bg-white text-sm"},[(_vm.circleColumns.includes(column))?_c('div',{staticClass:"text-gray-900 whitespace-no-wrap w-auto"},[_c('div',{staticClass:"pl-3 h-10 w-10 bg-gray-200 rounded-full animate-pulse"})]):_c('div',{staticClass:"text-gray-900 whitespace-no-wrap"},[_c('div',{staticClass:"h-6 bg-gray-200 rounded animate-pulse"})])])}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }