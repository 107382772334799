
import NavBar from "@/components/NavBar.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import { ModuleNames } from "@/store";
import AppBreadcrumbs from "@/components/app/AppBreadcrumbs.vue";
import AppModal from "@/components/app/modal/AppModal.vue";
import detectAdBlock from "@/helpers/adblock";

export default Vue.extend({
  components: { AppModal, AppBreadcrumbs, NavBar, Loader },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters(ModuleNames.auth, ["isAuthenticated"]),
  },
  data: function () {
    return {
      closeAdBlockModal: {
        visible: false,
        title: this.$t("detectedAdBlockTitle") as string,
        description: this.$t("detectedAdBlockDesc") as string,
      },
    };
  },
  methods: {
    ...mapActions(["updateBreadcrumbs"]),
    checkPermissions(): void {
      if (this.isAuthenticated && this.$route.meta?.isGuest) {
        this.$router.push("/account");
      }
      if (!this.isAuthenticated && this.$route.meta?.isAuthenticated) {
        this.$router.push("/login");
      }
    },
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(): void {
        this.checkPermissions();
      },
    },
    $route: {
      immediate: true,
      handler(): void {
        this.checkPermissions();
        this.updateBreadcrumbs(this.$route);
        setTimeout(async () => {
          let adBlockEnabled = await detectAdBlock();
          if (adBlockEnabled) this.closeAdBlockModal.visible = true;
        }, 2000);
      },
    },
  },
});
