var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mx-auto"},[_c('h1',{staticClass:"font-bold text-2xl mb-2 text-center"},[_vm._v(" "+_vm._s(_vm.game?.name)+" ")]),(_vm.loading)?_c('LoaderView'):_vm._e(),(_vm.game?.contests.length)?_vm._l((_vm.game?.contests),function(contest){return _c('AppListItem',{key:contest._id,attrs:{"item":{
        title: contest.title,
        description: contest.description,
        details: new Date(contest.date).toDateString(),
        id: contest._id,
        logo: contest.logo,
      }},on:{"click":function($event){return _vm.clickedContest(contest)}}})}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }