
import Vue from "vue";
import type { PropType } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default Vue.extend({
  name: "AppSelect",
  props: {
    value: { type: undefined, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    items: {
      type: Array as PropType<Array<{ value: any; label: string }>>,
      default: () => [],
    },
  },
  components: { SvgIcon },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    selectedItem(): { value: any; label: string } | undefined {
      if (this.value) {
        return this.items.find(
          (item: { value: any; label: string }) => item.value === this.value
        );
      }
      return undefined;
    },
  },
  methods: {
    closeDropdown(): void {
      this.isOpen = false;
    },
    selectItem(value: any): void {
      this.$emit("input", value);
      this.isOpen = false;
    },
  },
});
