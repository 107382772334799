
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import LoaderView from "@/components/Loader.vue";
import { convertStringToUrl } from "@/helpers/url";

export default Vue.extend({
  name: "GamesView",
  components: { LoaderView },
  data: function () {
    return { loading: false };
  },
  computed: {
    ...mapGetters(ModuleNames.game, ["games"]),
  },
  async mounted(): Promise<void> {
    if (!this.games.length) {
      try {
        this.loading = true;
        await this.fetchGamesList();
      } catch (e: any) {
        // this.showErrorToast(
        //   (this.$t("returnedError") as string) +
        //     (e.response.data.message
        //       ? ((",<br>" + this.$t("details")) as string) +
        //         ": " +
        //         e.response.data.message
        //       : "")
        // );
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions(ModuleNames.game, ["fetchGamesList"]),

    convertStringToUrl(string: string): string {
      return convertStringToUrl(string);
    },
  },
});
