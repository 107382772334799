import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import VueI18n from "vue-i18n";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { defaultLocale, messages } from "./i18n";
import Vuelidate from "vuelidate";
import VueTippy, { TippyComponent } from "vue-tippy";
import Ads from "vue-google-adsense";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const script2 = require("vue-script2");

Vue.use(script2);
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

Vue.use(VueI18n);
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.use(VueTippy, {
  directive: "tippy",
  arrow: true,
  arrowType: "round",
  onShow: (options: any) => {
    return !!options.props.content;
  },
});
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("tippy", TippyComponent);
const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});
Vue.directive("click-outside", {
  bind(el: any, binding: any, vnode: any) {
    el.clickOutsideEvent = function (event: Event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el: any): void {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export { i18n };
