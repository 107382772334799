
import Vue from "vue";
import type { PropType } from "vue";

export default Vue.extend({
  name: "AppTableSkeleton",
  props: {
    circleColumns: {
      type: Array as PropType<Array<number>>,
      default: () => [1],
    },
    columns: { type: Number, default: 5 },
  },
});
