
import Vue from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default Vue.extend({
  name: "AppInput",
  components: {
    SvgIcon,
  },
  props: {
    value: { type: undefined, default: "" },
    label: { type: undefined, default: "" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
    helperPosition: { type: String, default: "left" },
    helperIcon: { type: String, default: "" },
    helperText: { type: String, default: "" },
    minHelperWidth: { type: Number, default: 0 },
    size: { type: String, default: "md" },
    step: { type: Number, default: 10 },
  },
  data: (): { model: any } => ({
    model: null,
  }),
  watch: {
    model(): void {
      if (this.model !== null && this.model !== undefined) {
        if (this.type !== "number") this.$emit("input", this.model);
        else if (this.model !== "") this.$emit("input", Number(this.model));
        else if (this.model === "") this.$emit("input", undefined);
      }
    },

    value(): void {
      this.model = this.value;
    },
  },
  created(): void {
    this.model = this.value;
  },
  methods: {
    onBlur(): void {
      if (this.model !== null && this.model !== undefined) {
        if (this.type !== "number") this.$emit("blur", this.model);
        else if (this.model !== "") this.$emit("input", Number(this.model));
        else if (this.model === "") this.$emit("input", undefined);
      }
    },
  },
});
