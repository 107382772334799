var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
    `py-${
      _vm.size === 'sm' ? '1' : '2'
    } px-4 text-${_vm.fontColor} border border-transparent transition ease-in duration-200 text-center text-base
    font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg`,
    _vm.backgroundColor
      ? `bg-${_vm.backgroundColor}-600 hover:bg-${_vm.backgroundColor}-700 focus:ring-${_vm.backgroundColor}-500 focus:ring-offset-${_vm.backgroundColor}-200`
      : `bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-100`,

    _vm.disabled || _vm.loading
      ? `opacity-70 cursor-not-allowed hover:bg-${
          _vm.backgroundColor ? _vm.backgroundColor + '-600' : 'bg-gray-300'
        }`
      : '',
  ],attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?_c('img',{staticClass:"h-6 mx-auto",attrs:{"src":"/images/loading.gif"}}):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }