import { User } from "@/types/user";
import { axiosInstance } from "@/helpers/auth";

const AuthService = {
  login: async function (payload: {
    email: string;
    password: string;
  }): Promise<{ accessToken: string; user: User }> {
    return axiosInstance({
      method: "post",
      url: `/api/auth/login`,
      data: payload,
    });
  },

  getCurrentUser: async function (): Promise<{ user: User }> {
    return axiosInstance.get(`/api/auth/user`);
  },
};
export default AuthService;
