
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UserRole } from "@/types/user";
import SvgIcon from "@/components/SvgIcon.vue";
import Vue from "vue";
import { ModuleNames, RootMutations } from "@/store";
import { AuthActions } from "@/store/auth";

export default Vue.extend({
  name: "NavBar",
  components: { SvgIcon },
  data: (): { isOpen: boolean } => ({
    isOpen: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, [
      "isAuthenticated",
      "isApproved",
      "authUser",
    ]),
    // lang(): string {
    //   return this.$i18n.locale;
    // },

    routes(): Array<{ label: string; path: { name: string } }> {
      const routes = [
        {
          label: this.$t("navbar.games") as string,
          path: {
            name: "games",
          },
        },
      ];
      if (!this.isAuthenticated) {
        routes.push({
          label: this.$t("navbar.login") as string,
          path: {
            name: "login",
          },
        });
      }
      if (this.authUser?.roles?.includes(UserRole.MODERATOR)) {
        routes.push({
          label: this.$t("navbar.users") as string,
          path: {
            name: "users",
          },
        });
      }
      return routes;
    },

    accountRoutes(): Array<{ label: string; path: { name: string } }> {
      return [
        {
          label: this.$t("navbar.yourProfile") as string,
          path: {
            name: "account",
          },
        },
        {
          label: this.$t("navbar.settings") as string,
          path: {
            name: "account-settings",
          },
        },
      ];
    },
  },
  watch: {
    $route(): void {
      this.closeDropdown();
    },
  },
  methods: {
    ...mapActions(ModuleNames.auth, [AuthActions.LOGOUT]),
    ...mapMutations(["setIsLoading"]),
    toggle(): void {
      this.isOpen = !this.isOpen;
    },

    async logoutUser(): Promise<void> {
      this.setIsLoading(true);
      try {
        await this.logout();
        // } catch (e) {
      } finally {
        this.setIsLoading(false);
      }
    },

    closeDropdown(): void {
      this.isOpen = false;
    },
  },
});
