
import { email, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import AppInput from "@/components/app/AppInput.vue";
import AppButton from "@/components/app/AppButton.vue";
import Vue from "vue";
import { getErrorMessage } from "@/helpers/validation";
import { ModuleNames } from "@/store";
import { AuthActions } from "@/store/auth";

export default Vue.extend({
  name: "LoginView",
  components: {
    AppInput,
    AppButton,
  },
  // middleware: ["is-guest"],
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data: (): {
    email: string;
    password: string;
    formError: string;
    isLoading: boolean;
  } => ({
    email: "",
    password: "",
    formError: "",
    isLoading: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, ["isAuthenticated"]),
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler(): void {
        if (this.isAuthenticated) {
          this.$router.push("/account");
        }
      },
    },
  },

  methods: {
    ...mapActions(ModuleNames.auth, [AuthActions.LOGIN]),
    getErrorMessage(validation: any): string {
      return getErrorMessage(validation);
    },
    async loginUser(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.showErrorToast(this.$t("formContainsErrors") as string);
        return;
      }
      this.isLoading = true;
      try {
        await this.login({ email: this.email, password: this.password });
      } catch (e: any) {
        // this.showErrorToast(this.$t("formContainsErrors") as string);
        this.formError = this.$t(e.message) as string;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
