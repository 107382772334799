
import Vue from "vue";

export default Vue.extend({
  name: "AppButton",
  props: {
    backgroundColor: { type: String, default: "" },
    fontColor: { type: String, default: "white" },
    text: { type: String, default: "OK" },
    size: { type: String, default: "md" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
});
