
import Vue from "vue";
import type { PropType } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AppBreadcrumbs",
  props: {},
  components: { SvgIcon },
  computed: {
    ...mapGetters(["breadcrumbs"]),
  },
  methods: {
    getLabel(label: string): string {
      if (label.startsWith("navbar.")) {
        return label.replace("navbar.", "");
      }
      return label;
    },
  },
});
