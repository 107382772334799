export default {
  GI: "IG",
  GL: "ŁG",
  Yes: "Tak",
  No: "Nie",
  Confirm: "Zatwierdź",
  save: "Zapisz",
  cancel: "Anuluj",
  close: "Zamknij",
  error: "Błąd",
  areYouSure: "Czy jesteś pewien?",
  emailHasBeenSent: "Email został wysłany.<br>Sprawdź swoją pocztę",
  returnedError: "Wystąpił błąd, spróbuj ponownie później",
  formContainsErrors: "Formularz zawiera błędy, popraw i spróbuj ponownie",
  dataUpdated: "Aktualizacja danych zakończona pomyślnie",
  details: "szczegóły",
  addTo: "Dodaj do",
  total: "razem",

  errors: {
    "auth/user-not-found": "Nie znaleziono użytkownika o podanym adresie email",
    "auth/wrong-password": "Podane hasło jest niepoprawne",
    "auth/email-already-in-use": "Istnieje już konto dla podanego adresu email",
  },
  changePassword: {
    description:
      "Na Twój adres e-mail zostanie wysłana wiadomość e-mail umożliwiająca zmianę hasła. Czy chcesz kontynuować?",
    yesContinue: "Tak, kontynuuj",
  },
  validation: {
    required: "To pole jest obowiązkowe",
    email: "Niepoprawny adres email",
    sameAsPassword: "Podane hasła nie są takie same",
    minLength: "Wprowadzona wartość jest zbyt krótka",
    minValue: "Wprowadzona wartość jest zbyt mała",
    url: "Wprowadzono niepoprawny url",
  },
  navbar: {
    home: "Główna",
    orders: "Zamówienia",
    users: "Użytkownicy",
    warehouse: "Magazyn",
    yourProfile: "Konto",
    settings: "Ustawienia",
    signOut: "Wyloguj",
    products: "Produkty",
    search: "Wyszukiwarka",
    login: "Logowanie",
    games: "Gry",
    account: "Konto",
  },
  breadcrumbs: {},
  login: {
    loginFormHeader: "Zaloguj się na swoje konto",
    yourEmail: "Twój email",
    yourPassword: "Twoje hasło",
    forgotYourPassword: "Nie pamiętasz hasła?",
    login: "Zaloguj",
    dontHaveAccount: "Nie masz konta?",
  },
  register: {
    registerFormHeader: "Rejestracja",
    yourName: "Twoje imię i nazwisko",
    yourEmail: "Twój email",
    yourPassword: "Twoje hasło",
    confirmPassword: "Powtórz hasło",
    save: "Zapisz",
    haveAccount: "Masz konto?",
    success: "Użytkownik został pomyślnie zarejestrowany",
  },
  account: {
    header: "Konto",
    settings: "Ustawienia",
    email: "Email",
    name: "Imię i nazwisko",
    phone: "Numer telefonu",
    info: "Informacje",
    password: "Hasło",
    repeatPassword: "Powtórz hasło",
    savePassword: "Zapisz hasło",
    changePassword: "Zmień hasło",
    admin: "Admin",
    moderator: "Moderator",
    user: "Użytkownik",
    role: "Rola użytkownika",
    notApproved:
      "Twoje konto nie zostało jeszcze zatwierdzone przez administratora",
  },
  users: {
    addNewUser: "Dodaj nowego użytkownika",
    name: "Imię i nazwisko",
    role: "Rola",
    createdAt: "Data utworzenia",
    approved: "Zatwierdzony",
    approvedAt: "Data potwierdzenia",
    email: "Email",
    phone: "Telefon",
    approveUser: "Chcesz zatwierdzić tego użytkownika?",
    yesApprove: "Tak, zatwierdź",
    userHasBeenApproved: "Użytkownik został zatwierdzony",
    userHasBeenDeleted: "Użytkownik został usunięty",
    yesDelete: "Tak, usuń",
    deleteUser: "Chcesz usunąć tego użytkownika?",
  },
  "Required moderator role": "Wymagana rola moderatora",
  "Required admin role": "Wymagana rola admina",
  "User Not found.": "Nie znaleziono użytkownika",
  "Invalid Password!": "Niepoprawne hasło",
  "Failed! Email is already in use!":
    "Istnieje już konto dla podanego adresu email",
  detectedAdBlockTitle: "Wygląda na to, że blokujesz reklamy",
  detectedAdBlockDesc:
    "Dzięki reklamom możesz bezpłatnie korzystać z serwisu i czytać stworzone przez nas treści. Rozważ wyłączenie blokady dla naszego serwisu. Dziękujemy!",
};
