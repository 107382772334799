import type { Commit, Dispatch } from "vuex";
import AuthService from "@/services/auth.service";
import { User } from "@/types/user";
import { TokenService } from "@/services/token.service";

export enum AuthActions {
  AUTH = "auth",
  LOGIN = "login",
  LOGOUT = "logout",
}

export enum AuthMutations {
  SET_USER = "setUser",
}
export interface AuthState {
  user: User | null;
}
export const state = (): AuthState => ({ user: null });

export const getters = {
  isAuthenticated: (state: AuthState) => !!state.user,
  authUser: (state: AuthState): User | null => state.user,
  isApproved: (state: AuthState) => state.user?.approved === true,
};

const actions = {
  [AuthActions.LOGIN]: async (
    { commit }: { commit: Commit },
    payload: { email: string; password: string }
  ) => {
    try {
      const r = await AuthService.login(payload);
      console.log(r);
      TokenService.saveToken(r.accessToken);
      commit(AuthMutations.SET_USER, r.user);
    } catch (e) {
      TokenService.removeToken();
      commit(AuthMutations.SET_USER, null);
      throw e;
    }
  },
  [AuthActions.LOGOUT]: async ({ commit }: { commit: Commit }) => {
    TokenService.removeToken();
    commit(AuthMutations.SET_USER, null);
  },
  [AuthActions.AUTH]: async ({ commit }: { commit: Commit }) => {
    try {
      const r = await AuthService.getCurrentUser();
      commit(AuthMutations.SET_USER, r.user);
    } catch (e) {
      TokenService.removeToken();
      commit(AuthMutations.SET_USER, null);
      throw e;
    }
  },
};

export const mutations = {
  [AuthMutations.SET_USER](state: AuthState, payload: User | null) {
    state.user = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
