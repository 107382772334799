
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { ModuleNames } from "@/store";
import LoaderView from "@/components/Loader.vue";
import { Contest, Game } from "@/types/game";
import { Breadcrumb } from "@/types/main";

export default Vue.extend({
  name: "ContestView",
  components: { LoaderView },
  data: function () {
    return { loading: false };
  },
  computed: {
    ...mapGetters(ModuleNames.game, ["games"]),
    ...mapGetters(["breadcrumbs"]),
    game(): Game | undefined {
      return this.games?.find(
        (game: Game) =>
          this.$route.params.game_uuid.split("-")?.[0] === game._id
      );
    },
    contest(): Contest | undefined {
      return this.game?.contests.find(
        (contest: Contest) =>
          this.$route.params.contest_uuid.split("-")?.[0] === contest._id
      );
    },
  },
  async mounted(): Promise<void> {
    if (!this.games.length) {
      try {
        this.loading = true;
        await this.fetchGamesList();
      } catch (e: any) {
        // this.showErrorToast(
        //   (this.$t("returnedError") as string) +
        //     (e.response.data.message
        //       ? ((",<br>" + this.$t("details")) as string) +
        //         ": " +
        //         e.response.data.message
        //       : "")
        // );
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions(ModuleNames.game, ["fetchGamesList"]),
    ...mapActions(["correctBreadcrumbsLabel"]),
  },
  watch: {
    game: {
      immediate: true,
      handler(): void {
        const breadcrumbs = [...this.breadcrumbs];

        if (this.game) {
          const breadcrumbGame = breadcrumbs.find((b: Breadcrumb) =>
            b.link.endsWith(this.$route.params.game_uuid)
          );
          breadcrumbGame.label = this.game.name;
        }
        if (this.contest) {
          const breadcrumbContest = breadcrumbs.find((b: Breadcrumb) =>
            b.link.endsWith(this.$route.params.contest_uuid)
          );
          breadcrumbContest.label = this.contest.title;
        }

        this.correctBreadcrumbsLabel(breadcrumbs);
      },
    },
  },
});
