
import { email, minLength, required } from "vuelidate/lib/validators";
import type { PropType } from "vue";
import { User, UserRole } from "@/types/user";
import AppInput from "@/components/app/AppInput.vue";
import AppButton from "@/components/app/AppButton.vue";
import AppSelect from "@/components/app/AppSelect.vue";
import Vue from "vue";
import { getErrorMessage } from "@/helpers/validation";

export default Vue.extend({
  name: "UserForm",
  components: { AppInput, AppButton, AppSelect },
  props: {
    user: { type: Object as PropType<any> },
    disabledFields: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    showChangePasswordButton: { type: Boolean, default: true },
  },
  validations: {
    userData: {
      displayName: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  data: (): {
    userRole: string;
    userData: User;
  } => ({
    userRole: "",
    userData: {
      _id: undefined,
      email: "",
      displayName: "",
      avatar: "",
      phone: "",
      roles: [],
      approved: false,
    },
  }),
  computed: {
    roles(): Array<{ value: UserRole; label: string }> {
      return [
        { value: UserRole.ADMIN, label: this.$t("account.admin") as string },
        {
          value: UserRole.MODERATOR,
          label: this.$t("account.moderator") as string,
        },
        { value: UserRole.USER, label: this.$t("account.user") as string },
      ];
    },
  },
  watch: {
    user(): void {
      this.setUserData();
    },
    userRoles(): void {
      if (this.userRole === UserRole.ADMIN) {
        this.userData.roles = [
          UserRole.USER,
          UserRole.MODERATOR,
          UserRole.ADMIN,
        ];
      } else if (this.userRole === UserRole.MODERATOR) {
        this.userData.roles = [UserRole.USER, UserRole.MODERATOR];
      } else {
        this.userData.roles = [UserRole.USER];
      }
    },
  },
  created() {
    this.setUserData();
  },
  methods: {
    getErrorMessage(validation: any): string {
      return getErrorMessage(validation);
    },
    setUserData(): void {
      if (this.user) {
        this.userData = {
          id: this.user.id,
          email: this.user.email,
          phone: this.user.phone,
          displayName: this.user.displayName,
          avatar: this.user.avatar,
          roles: this.user.roles,
          approved: this.user.approved,
        };
        if (this.user.roles?.includes(UserRole.ADMIN)) {
          this.userRole = UserRole.ADMIN;
        } else if (this.user.roles?.includes(UserRole.MODERATOR)) {
          this.userRole = UserRole.MODERATOR;
        } else {
          this.userRole = UserRole.USER;
        }
      }
    },
    saveForm(): void {
      this.$v.userData.$touch();
      if (this.$v.$invalid) {
        // this.showErrorToast(this.$t("formContainsErrors") as string);
        return;
      }
      this.$emit("submit", this.userData);
    },
  },
});
