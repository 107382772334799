export default {
  save: "Save",
  cancel: "Cancel",
  close: "Close",
  error: "Error",
  areYouSure: "Are you sure?",
  emailHasBeenSent: "Email has been sent.<br>Check your mail",
  returnedError: "An error occurred, please try again later",
  formContainsErrors: "The form contains errors, correct it and try again",
  dataUpdated: "Data update completed successfully",
  addTo: "Add to",
  errors: {
    "auth/user-not-found": "No user with the given email address was found",
    "auth/wrong-password": "The password was incorrect",
    "auth/email-already-in-use":
      "There is already an account for the given email address",
  },
  changePassword: {
    description:
      "An email will be sent to your email address that allows you to change your password. Do you want to continue?",
    yesContinue: "Yes, continue",
  },
  validation: {
    required: "This field is required",
    email: "Invalid email address",
    sameAsPassword: "The entered passwords are not the same",
    minLength: "Entered value is too short",
  },
  navbar: {
    home: "Home",
    orders: "Orders",
    users: "Users",
    warehouse: "Warehouse",
    yourProfile: "Your Profile",
    settings: "Settings",
    signOut: "Sign out",
    products: "Products",
    search: "Search",
    login: "Login",
    games: "Games",
  },
  login: {
    loginFormHeader: "Login To Your Account",
    yourEmail: "Your email",
    yourPassword: "Your password",
    forgotYourPassword: "Forgot Your Password?",
    login: "Login",
    dontHaveAccount: "You don't have an account?",
  },
  register: {
    registerFormHeader: "Register",
    yourName: "Your name",
    yourEmail: "Your email",
    yourPassword: "Your password",
    confirmPassword: "Confirm password",
    save: "Save",
    haveAccount: "You do have an account?",
    success: "User was registered successfully",
  },
  account: {
    header: "Account",
    settings: "Settings",
    email: "Email",
    name: "Name",
    phone: "Phone",
    info: "Information",
    password: "Password",
    repeatPassword: "Repeat password",
    savePassword: "Save Password",
    changePassword: "Change Password",
    admin: "Admin",
    moderator: "Moderator",
    user: "User",
    role: "User role",
    notApproved: "Your account has not been approved by the admin yet",
  },
  users: {
    addNewUser: "Add new user",
    name: "Name",
    role: "Role",
    createdAt: "Created at",
    approved: "Approved",
    approvedAt: "Approved At",
    email: "Email",
    phone: "Phone",
    approveUser: "Do you want to approved this user?",
    yesApprove: "Yes, approve",
    userHasBeenApproved: "The user has been approved",
    userHasBeenDeleted: "The user has been deleted",
    yesDelete: "Yes, delete",
    deleteUser: "Do you want to delete this user?",
  },
  add: "Add",
};
