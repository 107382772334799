
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import AppInput from "@/components/app/AppInput.vue";
import AppButton from "@/components/app/AppButton.vue";
import Vue from "vue";
import { getErrorMessage } from "@/helpers/validation";
import { ModuleNames } from "@/store";
import { UserActions } from "@/store/user";

export default Vue.extend({
  name: "RegisterView",
  components: { AppInput, AppButton },
  // middleware: ["is-guest"],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(7),
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  data: (): {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    formError: string;
    isLoading: boolean;
  } => ({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    formError: "",
    isLoading: false,
  }),
  computed: {
    ...mapGetters(ModuleNames.auth, ["isAuthenticated"]),
  },
  watch: {
    isAuthenticated(): void {
      this.checkThatAuthenticated();
    },
  },
  mounted(): void {
    this.checkThatAuthenticated();
  },
  methods: {
    ...mapActions(ModuleNames.user, [UserActions.REGISTER_NEW_USER]),
    getErrorMessage(validation: any): string {
      return getErrorMessage(validation);
    },
    async registerUser(): Promise<void> {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.showErrorToast(this.$t("formContainsErrors") as string);
        return;
      }
      this.isLoading = true;
      try {
        await this.registerNewUser({
          email: this.email,
          password: this.password,
          confirmPassword: this.confirmPassword,
          displayName: this.name,
        });
        // this.showSuccessToast(this.$t("register.success") as string);
        this.$router.push({ name: "login" });
      } catch (e: any) {
        console.log(e);
        // this.showErrorToast(this.$t("formContainsErrors") as string);
        this.formError = this.$t(e.message) as string;
      } finally {
        this.isLoading = false;
      }
    },
    checkThatAuthenticated(): void {
      if (this.isAuthenticated) {
        this.$router.push({ name: "account" });
      }
    },
  },
});
