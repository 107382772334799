import { User } from "@/types/user";

export enum SortType {
  ASC = "asc",
  DESC = "desc",
}
export interface Auth {
  loggedIn: boolean;
  user: User;
}

export interface Breadcrumb {
  link: string;
  label: string;
}
